<template>
  <Toast />
  <div class="p-grid crud-demo">
    <div class="p-col-12 p-md-6">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openFuncao"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="funcoes"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedFuncao"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filtersFuncao"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} funções"
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Funções</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filtersFuncao['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column
            field="descricao"
            header="Descrição da Função"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Descrição</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="openEditFuncao(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="openDeleteFuncao(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- CADASTRAR E ATUALIZAR FUNÇÕES-->
        <Dialog
          v-model:visible="funcaoDialog"
          :style="{ width: '480px' }"
          :header="titleDialog"
          :modal="true"
          @hide="hideDialog"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="descricao">Descreva a Função</label>
            <InputText
              id="descricao"
              v-model.trim="funcao.descricao"
              required="true"
              autofocus
              placeholder="Informe a função."
              maxlength="255"
              :class="{ 'p-invalid': submitted && !funcao.descricao }"
            />
            <small class="p-invalid" v-if="submitted && !funcao.descricao"
              >Campo {{ msgUsuario }}.</small
            >
          </div>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendFuncao"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM CADASTRAR E ATUALIZAR FUNÇÕES-->

        <!-- EXCLUIR FUNÇÕES-->
        <Dialog
          v-model:visible="deletefuncaoDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
          header="Mensagem de Confirmação!"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="funcao"
              >Tem certeza de que deseja excluir o registro
              <b>{{ funcao.descricao }} ?</b></span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteFuncao"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM EXCLUIR FUNÇÕES-->
      </div>
    </div>

    <div class="p-col-12 p-md-6">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openCargo"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="cargos"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedCargo"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filtersCargo"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 15, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} funções"
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Cargos</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filtersCargo['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column
            field="descricao"
            header="Descrição do Cargo"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Descrição</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="openEditCargo(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="openDeleteCargo(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- CADASTRAR E ATUALIZAR CARGO-->
        <Dialog
          v-model:visible="cargoDialog"
          :style="{ width: '480px' }"
          :header="titleDialog"
          :modal="true"
          @hide="hideDialog"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="descricao">Descreva o Cargo</label>
            <InputText
              id="descricao"
              v-model.trim="cargo.descricao"
              required="true"
              autofocus
              placeholder="Informe o cargo."
              maxlength="255"
              :class="{ 'p-invalid': submitted && !cargo.descricao }"
            />
            <small class="p-invalid" v-if="submitted && !cargo.descricao"
              >Campo {{ msgUsuario }}.</small
            >
          </div>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendCargo"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM CADASTRAR E ATUALIZAR CARGO-->

        <!-- EXCLUIR CARGO-->
        <Dialog
          v-model:visible="deleteCargoDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
          header="Mensagem de Confirmação!"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="cargo"
              >Tem certeza de que deseja excluir o registro
              <b>{{ cargo.descricao }} ?</b></span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteCargo"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM EXCLUIR CARGO-->
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "@/service/Auth/AuthService";
import FuncaoService from "@/service/Funcao/FuncaoService";
import CargoService from "@/service/Cargo/CargoService";

export default {
  data() {
    return {
      /**INICIO VARIAVEIS FUNCOES */
      funcoes: null,
      funcaoDialog: false,
      deletefuncaoDialog: false,
      selectedFuncao: null,
      funcao: {
        id: null,
        descricao: null,
      },
      /**FIM VARIAVEIS FUNCOES */
      /**INICIO VARIAVEIS CARGOS */
      cargos: null,
      cargoDialog: false,
      deleteCargoDialog: false,
      selectedCargo: null,
      cargo: {
        id: null,
        descricao: null,
      },
      /**FIM VARIAVEIS CARGOS */
      filtersCargo: {},
      filtersFuncao: {},
      submitted: false,
      titleDialog: null,
      msg: null,
      msgUsuario: null,
      msgDesenvolvedor: null,
      reqError: false,
    };
  },
  funcaoService: null,
  cargoService: null,
  authService: null,
  created() {
    this.authService = new AuthService();
    this.funcaoService = new FuncaoService();
    this.cargoService = new CargoService();
  },
  mounted() {
    this.authService.checarToken(this.listFuncoes());
    this.authService.checarToken(this.listCargos());
  },
  methods: {
    /* INICIO FUNCOES */
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE CADASTRAR FUNCOES*/
    openFuncao() {
      this.titleDialog = "Nova Função";
      this.submitted = false;
      this.funcaoDialog = true;
    },
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE EDITAR FUNCOES*/
    openEditFuncao(funcao) {
      this.funcao = funcao;
      this.titleDialog = this.funcao.descricao;
      this.funcaoDialog = true;
    },
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE DELETAR FUNCOES*/
    openDeleteFuncao(funcao) {
      this.funcao = funcao;
      this.deletefuncaoDialog = true;
    },
    /*METODO RESPONSAVEL PELO RESET DA MODAI'S*/
    hideDialog() {
      this.funcaoDialog = false;
      this.deletefuncaoDialog = false;
      this.cargoDialog = false;
      this.deleteCargoDialog = false;
      this.submitted = false;
      this.funcao = {};
      this.cargo = {};
      this.msgUsuario = null;
      this.msgDesenvolvedor = null;
    },
    /*METODO RESPONSAVEL PELA LISTAGEM DA FUNCOES*/
    listFuncoes() {
      this.funcaoService.listar().then((data) => {
        this.funcoes = data;
      });
    },
    /*METODO RESPONSAVEL POR SALVAR E EDITAR UMA NOVA FUNÇÃO*/
    saveFuncao() {
      this.submitted = true;
      if (this.funcao.id == null) {
        this.funcaoService
          .criar(this.funcao)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Função salva com sucesso.",
                life: 3000,
              });
              this.listFuncoes();
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.reqError = true;
            this.funcao.descricao = null;
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
          });
      } else {
        this.funcaoService
          .atualizar(this.funcao.id, this.funcao)
          .then((data) => {
            if (data.status === 200) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Funcão atualizada com sucesso.",
                life: 3000,
              });
              this.hideDialog();
            }
          });
      }
    },
    /*METODO RESPONSAVEL POR DELETAR UMA FUNÇÃO*/
    deletarFuncao() {
      this.funcaoService.remover(this.funcao.id).then((data) => {
        if (data.status === 204) {
          this.deletefuncaoDialog = false;
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Função  Deletada com sucesso.",
            life: 3000,
          });
          this.listFuncoes();
        } else {
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 3000,
          });
        }
      });
    },
    /*METODOS RESPONSAVEIS POR PELA CHEKAGEM DE TOKEN NA OPERAÇÕES DE CADASTRAR,ALTERAR E EXCLUIR*/
    sendFuncao() {
      this.authService.checarToken(this.saveFuncao());
    },
    deleteFuncao() {
      this.authService.checarToken(this.deletarFuncao());
    },
    /* FIM FUNCOES */
    /* INICIO CARGOS */
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE CADASTRAR FUNCOES*/
    openCargo() {
      this.titleDialog = "Novo Cargo";
      this.cargoDialog = true;
    },
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE EDITAR FUNCOES*/
    openEditCargo(cargo) {
      this.cargo = cargo;
      this.titleDialog = this.cargo.descricao;
      this.cargoDialog = true;
    },
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE DELETAR FUNCOES*/
    openDeleteCargo(cargo) {
      this.cargo = cargo;
      this.deleteCargoDialog = true;
    },
    /*METODO RESPONSAVEL PELA LISTAGEM DA FUNCOES*/
    listCargos() {
      this.cargoService.listar().then((data) => {
        this.cargos = data;
      });
    },
    /*METODO RESPONSAVEL POR SALVAR E EDITAR UMA NOVA FUNÇÃO*/
    saveCargo() {
      this.submitted = true;
      if (this.cargo.id == null) {
        this.cargoService
          .criar(this.cargo)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Cargo salvo com sucesso.",
                life: 3000,
              });
              this.listCargos();
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.reqError = true;
            this.cargo.descricao = null;
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
          });
      } else {
        this.cargoService.atualizar(this.cargo.id, this.cargo).then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Cargo atualizado com sucesso.",
              life: 3000,
            });
            this.hideDialog();
          }
        });
      }
    },
    /*METODO RESPONSAVEL POR DELETAR UMA FUNÇÃO*/
    deletarCargo() {
      this.cargoService.remover(this.cargo.id).then((data) => {
        if (data.status === 204) {
          this.listCargos();
          this.deleteCargoDialog = false;
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Cargo Deletado com sucesso.",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 3000,
          });
        }
      });
    },
    /*METODOS RESPONSAVEIS POR PELA CHEKAGEM DE TOKEN NA OPERAÇÕES DE CADASTRAR,ALTERAR E EXCLUIR*/
    sendCargo() {
      this.authService.checarToken(this.saveCargo());
    },
    deleteCargo() {
      this.authService.checarToken(this.deletarCargo());
    },
    /* FIM CARGOS */
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setor-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .setor-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setor-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>